import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "@/store";

import "./plugins/vue-cookies";
import vuetify from "./plugins/vuetify";

import Notifications from "vue-notification";
import VueClipboard from "vue-clipboard2";
import { setInteractionMode } from "vee-validate";

import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import { version } from "../package.json";

Vue.use(Notifications);
setInteractionMode("eager");

VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    Vue,
    dsn: "https://de98acd93363465d81f398fbf61d1264@o235257.ingest.sentry.io/6406502",
    environment: "production",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ["localhost", "reachy.codedge.io", /^\//],
      }),
    ],
    release: `reachy-web@${version}`,
    tracesSampleRate: 1.0,
    tracingOptions: {
      trackComponents: true,
    },
  });
}

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
