import TemplateJson from "./templateJson";

export default class Template {
  createdAt: string | null;
  id: string | null;
  name: string | null;

  constructor() {
    this.createdAt = null;
    this.id = null;
    this.name = null;
  }

  static fromJSON(json: TemplateJson): Template {
    const template = new Template();

    template.createdAt = json.created_at;
    template.id = json.id;
    template.name = json.name;

    return template;
  }

  toJSON(): any {
    return {
      name: this.name,
    };
  }
}
