import axios from "axios";
import Contact from "../models/contact";
import Response from "../models/response";
import Util from "./util";

export default {
  all(params: any): Promise<Response> {
    const url = process.env.VUE_APP_API_URL + "/api/v1/contacts";

    return axios
      .get(url, { params: params, headers: Util.authHeaders() })
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },

  create(contact: Contact): Promise<Response> {
    const url = process.env.VUE_APP_API_URL + "/api/v1/contacts";
    const params = contact.toJSON();

    return axios
      .post(url, params, { headers: Util.authHeaders() })
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },

  delete(contactId: string): Promise<Response> {
    const url = `${process.env.VUE_APP_API_URL}/api/v1/contacts/${contactId}`;

    return axios
      .delete(url, { headers: Util.authHeaders() })
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },

  export(): Promise<Response> {
    const url = process.env.VUE_APP_API_URL + "/api/v1/contacts/export";

    return axios
      .post(url, {}, { headers: Util.authHeaders() })
      .then((resp: any) => resp.data)
      .catch((error: any) => Util.handleResponse(error));
  },

  importCSV(file: File) {
    const url = process.env.VUE_APP_API_URL + "/api/v1/contacts/import";

    const formData = new FormData();
    formData.append("file", file);

    let headers: Record<string, string> = {};
    headers["Content-Type"] = "multipart/form-data";
    headers = Object.assign(headers, Util.authHeaders());

    return axios
      .post(url, formData, { headers: headers })
      .then((resp: any) => resp.data)
      .catch((error: any) => Util.handleResponse(error));
  },
};
