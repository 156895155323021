
import AddContacts from "../components/campaigns/AddContacts.vue";
import AddTemplate from "../components/campaigns/AddTemplate.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import Campaign from "../models/campaign";
import CampaignContact from "../models/campaignContact";
import ContactsTable from "../components/contacts/ContactsTable.vue";
import Template from "../models/template";
import { namespace } from "vuex-class";
const { DateTime } = require("luxon");

const campaign = namespace("campaign");
const template = namespace("template");

@Component({
  components: {
    AddContacts,
    AddTemplate,
    ContactsTable,
  },
})
export default class CampaignDetail extends Vue {
  @Prop({ required: true }) readonly campaignId!: string;

  breadcrumbs: any[] = [
    { text: "Campaigns", href: "/campaigns" },
    { text: this.campaignId, href: `/campaigns/${this.campaignId}` },
  ];

  isContactsDialogOpen = false;
  isPreviewDialogOpen = false;
  isTemplatesDialogOpen = false;

  @campaign.Getter("getCampaign")
  getCampaign!: (campaignId: string) => Campaign;

  @campaign.Getter("getContacts")
  getContacts!: (campaignId: string) => CampaignContact[];

  @template.Getter("getTemplates")
  templates!: Template[];

  @template.Action("fetchAll")
  fetchAllTemplates!: () => Promise<any>;

  @campaign.Action("attachTemplate")
  attachTemplate!: (params: any) => Promise<any>;

  @campaign.Action("clearTemplate")
  clearTemplate!: (campaignId: string) => Promise<any>;

  get campaign(): Campaign {
    return this.getCampaign(this.campaignId);
  }

  get contacts(): CampaignContact[] {
    return this.getContacts(this.campaignId);
  }

  get previewUrl(): string {
    if (!this.campaign.template) {
      return "";
    }
    return `${process.env.VUE_APP_API_URL}/api/v1/templates/${this.campaign.template.id}/preview`;
  }

  @campaign.Action("fetch")
  fetch!: (campaignId: string) => Promise<any>;

  @campaign.Action("fetchAllContacts")
  fetchAllContacts!: (campaignId: string) => Promise<any>;

  get existingContactIds(): (string | null)[] {
    return this.contacts.map((x: CampaignContact) => x.id);
  }

  mounted(): void {
    this.fetch(this.campaignId);
    this.fetchAllContacts(this.campaignId);
    this.fetchAllTemplates();
  }

  datetimeString(datetime: string): string {
    return DateTime.fromISO(datetime).toLocaleString(DateTime.DATETIME_MED);
  }

  requestAttachTemplate(template: Template): void {
    this.attachTemplate({ campaignId: this.campaignId, template: template });
    this.isTemplatesDialogOpen = false;
  }
}
