
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

import CreateContact from "../components/contacts/CreateContact.vue";
import ContactsTable from "../components/contacts/ContactsTable.vue";
import Contact from "../models/contact";
import ContactApi from "../api/contact";
import Response from "../models/response";
import UploadFile from "../components/UploadFile.vue";

const contact = namespace("contact");

@Component({
  components: {
    ContactsTable,
    CreateContact,
    UploadFile,
  },
})
export default class Contacts extends Vue {
  createDialog = false;
  isUploadDialogOpen = false;
  selectedContactId: string | null = null;

  @contact.Getter("getContacts")
  contacts!: Contact[];

  @contact.Action("create")
  createContact!: (params: any) => Promise<any>;

  @contact.Action("delete")
  deleteContact!: (contactId: string) => Promise<any>;

  @contact.Action("fetchAll")
  fetchAllContacts!: () => Promise<any>;

  get hasSelectedContacts(): boolean {
    return this.selectedContactId !== null;
  }

  mounted(): void {
    this.fetchAllContacts();
  }

  close(): void {
    this.createDialog = false;
  }

  save(contact: Contact): void {
    this.createContact({ contact: contact })
      .then((_resp: Response) => {
        this.close();

        const dialog: any = this.$refs.createContactDialog;
        dialog.reset();
      })
      .catch((resp: Response) => {
        const dialog: any = this.$refs.createContactDialog;
        dialog.errorMessage = resp.error.message;
      })
      .finally(() => {
        const dialog: any = this.$refs.createContactDialog;
        dialog.isLoading = false;
      });
  }

  deleteSelectedContacts(): void {
    if (this.selectedContactId === null) {
      return;
    }
    this.deleteContact(this.selectedContactId);
    this.selectedContactId = null;
  }

  updateSelectedContactIds(contactIds: string[]): void {
    if (contactIds.length === 0) {
      this.selectedContactId = null;
    } else {
      this.selectedContactId = contactIds[0];
    }
  }

  showDialog(): void {
    this.createDialog = true;
  }

  exportData(): void {
    ContactApi.export().then((resp: any) => {
      const fileURL = window.URL.createObjectURL(new Blob([resp]));
      const fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute("download", "export.csv");
      document.body.appendChild(fileLink);

      fileLink.click();
    });
  }

  importCSV(file: File): void {
    ContactApi.importCSV(file).then((_resp: any) => {
      this.isUploadDialogOpen = false;
      (this.$refs.uploadFile as UploadFile).reset();
      this.fetchAllContacts();
    });
  }
}
