export default class CampaignContact {
  createdAt: string | null;
  email: string | null;
  firstName: string | null;
  id: string | null;
  lastActivity: string | null;
  lastName: string | null;
  status: string | null;

  constructor() {
    this.createdAt = null;
    this.email = null;
    this.firstName = null;
    this.id = null;
    this.lastActivity = null;
    this.lastName = null;
    this.status = null;
  }

  static fromJSON(json: Record<string, string>): CampaignContact {
    const contact = new CampaignContact();

    contact.createdAt = json.created_at;
    contact.email = json.email;
    contact.firstName = json.first_name;
    contact.id = json.id;
    contact.lastActivity = json.last_activity;
    contact.lastName = json.last_name;
    contact.status = json.status;

    return contact;
  }

  toJSON(): any {
    return {
      first_name: this.firstName,
      last_name: this.lastName,
      email: this.email,
    };
  }

  fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}
