
import Vue from "vue";

export default Vue.extend({
  name: "SubAction",

  props: {
    color: {
      type: String,
      default: "transparent",
    },

    text: {
      type: String,
      required: true,
    },

    linkText: {
      type: String,
      required: true,
    },

    to: {
      type: Object,
      required: true,
    },
  },
});
