import TemplateApi from "@/api/template";
import {
  ActionContext,
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from "vuex";
import { State as RootState } from "../state";
import { TemplateState } from "./templateState";
import Template from "../../models/template";
import Response from "../../models/response";

type TemplateContext = ActionContext<TemplateState, RootState>;

const state: TemplateState = {
  templates: [],
};

const getters: GetterTree<TemplateState, RootState> = {
  getTemplates(state: TemplateState): Template[] {
    return state.templates;
  },
};

const actions: ActionTree<TemplateState, RootState> = {
  create(context: TemplateContext, { name, file }): Promise<Response> {
    return new Promise((resolve, reject) => {
      context.commit("createRequest");

      TemplateApi.create(name, file)
        .then((resp: Response) => {
          const data: Template = Template.fromJSON(resp.data);
          context.commit("createSuccess", { template: data });
          resolve(resp);
        })
        .catch((resp: Response) => {
          context.commit("createFailure");
          reject(resp);
        });
    });
  },

  fetchAll(context: TemplateContext): Promise<Response> {
    return new Promise((resolve, reject) => {
      context.commit("fetchAllRequest");

      TemplateApi.all({})
        .then((resp: Response) => {
          const data = resp.data.templates.map((d: any) =>
            Template.fromJSON(d),
          );
          context.commit("fetchAllSuccess", { templates: data });
          resolve(resp);
        })
        .catch((resp: Response) => {
          context.commit("fetchAllFailure");
          reject(resp);
        });
    });
  },

  delete(context: TemplateContext, templateId: string): Promise<Response> {
    return new Promise((resolve, reject) => {
      context.commit("deleteRequest");

      TemplateApi.delete(templateId)
        .then((resp: Response) => {
          context.commit("deleteSuccess", { templateId: templateId });
          resolve(resp);
        })
        .catch((resp: Response) => {
          context.commit("deleteFailure");
          reject(resp);
        });
    });
  },
};

const mutations: MutationTree<TemplateState> = {
  createRequest(_state: TemplateState) {
    // Nothing yet.
  },

  createSuccess(state: TemplateState, { template }) {
    state.templates.push(template);
  },

  createFailure(_state: TemplateState) {
    // Nothing yet.
  },

  fetchAllRequest(_state: TemplateState) {
    // Nothing yet.
  },

  fetchAllSuccess(state: TemplateState, { templates }) {
    state.templates = templates;
  },

  fetchAllFailure(_state: TemplateState) {
    // Nothing yet.
  },

  deleteRequest(_state: TemplateState) {
    // Nothing yet.
  },

  deleteSuccess(state: TemplateState, { templateId }) {
    state.templates = state.templates.filter((x) => x.id !== templateId);
  },

  deleteFailure(_state: TemplateState) {
    // Nothing yet.
  },
};

export const template: Module<TemplateState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
