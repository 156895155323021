
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Contact from "../../models/contact";
const { DateTime, Interval } = require("luxon");
import NullableField from "../NullableField.vue";

@Component({
  components: {
    NullableField,
  },
})
export default class ContactsTable extends Vue {
  @Prop({ required: true }) readonly contacts!: Contact[];
  @Prop({ default: false }) readonly singleSelect!: boolean;
  @Prop({ default: false }) readonly detailed!: boolean;

  simpleHeaders: Record<string, any>[] = [
    {
      text: "Last Name",
      value: "lastName",
    },
    {
      text: "First Name",
      value: "firstName",
    },
    {
      text: "Company",
      value: "company",
    },
    {
      text: "Email",
      value: "email",
    },
    {
      text: "Phone No.",
      value: "phoneNo",
    },
  ];

  detailHeaders: Record<string, any>[] = [
    {
      text: "Last Name",
      value: "lastName",
    },
    {
      text: "First Name",
      value: "firstName",
    },
    {
      text: "Email",
      value: "email",
    },
    {
      text: "Status",
      value: "status",
    },
    {
      text: "Last Activity",
      value: "lastActivity",
    },
  ];

  isLoading = false;

  selected: string[] = [];

  options: any = {
    sortDesc: [false],
    page: 1,
    itemsPerPage: 20,
    sortBy: ["lastName"],
  };

  itemsPerPageOptions = [20, 50];
  totalItems = 0;

  get headers(): Record<string, any>[] {
    return this.detailed ? this.detailHeaders : this.simpleHeaders;
  }

  @Watch("selected")
  updateSelected(newVal: Contact[], _oldVal: Contact[]): void {
    const contactIds = newVal.map((contact) => contact.id);
    this.$emit("update-selected", contactIds);
  }

  get footerProps(): any {
    return { "items-per-page-options": this.itemsPerPageOptions };
  }

  datetimeString(datetime: string): string {
    return DateTime.fromISO(datetime).toLocaleString(DateTime.DATETIME_MED);
  }

  daysAgoString(datetime: string): string {
    const dt = DateTime.fromISO(datetime);
    const days = Interval.fromDateTimes(dt, DateTime.local()).toDuration(
      "days",
    ).days;

    if (days < 1) {
      return "< 1 day";
    } else if (days >= 1 && days < 2) {
      return "1 day";
    } else {
      return `${Math.round(days)} days`;
    }
  }

  offset(): number {
    const { page, itemsPerPage } = this.options;
    return (page - 1) * itemsPerPage;
  }

  sortParam(key: string): string {
    const keys: any = {
      createdAt: "inserted_at",
      id: "id",
      name: "name",
      companyName: "company_name",
      firstName: "first_name",
      lastName: "last_name",
      jobTitle: "job_title",
      stage: "stage",
      frMrr: "fr_mrr",
      frLicenseCount: "fr_license_count",
      email: "email",
      numberOfAgents: "num_agents",
    };

    return keys[key];
  }

  daysAgoColor(datetime: string): string {
    const dt = DateTime.fromISO(datetime);
    const days = Interval.fromDateTimes(dt, DateTime.local()).toDuration(
      "days",
    ).days;

    if (days > 60) {
      return "red";
    } else if (days > 30 && days <= 60) {
      return "orange";
    } else {
      return "primary";
    }
  }

  statusColor(status: string): string {
    switch (status) {
      case "delivered":
        return "green--text";
      case "opened":
        return "primary--text";
      case "failed":
        return "red--text";
      case "unsubscribed":
        return "warning--text";
      default:
        return "grey--text";
    }
  }
}
