import CampaignApi from "@/api/campaign";
import {
  ActionContext,
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from "vuex";
import { State as RootState } from "../state";
import { CampaignState } from "./campaignState";
import Campaign from "../../models/campaign";
import CampaignContact from "../../models/campaignContact";
import Response from "../../models/response";
import Vue from "vue";

type CampaignContext = ActionContext<CampaignState, RootState>;

const state: CampaignState = {
  campaigns: {},
  campaignIds: [],
  contacts: {},
};

const getters: GetterTree<CampaignState, RootState> = {
  getCampaign:
    (state: CampaignState) =>
    (campaignId: string): Campaign => {
      return state.campaigns[campaignId] || new Campaign();
    },

  getCampaigns(state: CampaignState): Campaign[] {
    return state.campaignIds.map((campaignId: string) => {
      return state.campaigns[campaignId] || new Campaign();
    });
  },

  getContacts:
    (state: CampaignState) =>
    (campaignId: string): CampaignContact[] => {
      return state.contacts[campaignId] || [];
    },
};

const actions: ActionTree<CampaignState, RootState> = {
  create(context: CampaignContext, { campaign }): Promise<Response> {
    return new Promise((resolve, reject) => {
      context.commit("createRequest");

      CampaignApi.create(campaign)
        .then((resp: Response) => {
          const data: Campaign = Campaign.fromJSON(resp.data);
          context.commit("createSuccess", { campaign: data });
          resolve(resp);
        })
        .catch((resp: Response) => {
          context.commit("createFailure");
          reject(resp);
        });
    });
  },

  fetch(context: CampaignContext, campaignId: string): Promise<Response> {
    return new Promise((resolve, reject) => {
      context.commit("fetchRequest");

      CampaignApi.get(campaignId)
        .then((resp: Response) => {
          const data = Campaign.fromJSON(resp.data);
          context.commit("fetchSuccess", { campaign: data });
          resolve(resp);
        })
        .catch((resp: Response) => {
          context.commit("fetchFailure");
          reject(resp);
        });
    });
  },

  fetchAll(context: CampaignContext): Promise<Response> {
    return new Promise((resolve, reject) => {
      context.commit("fetchAllRequest");

      CampaignApi.all({})
        .then((resp: Response) => {
          const data = resp.data.campaigns.map((d: any) =>
            Campaign.fromJSON(d),
          );
          context.commit("fetchAllSuccess", { campaigns: data });
          resolve(resp);
        })
        .catch((resp: Response) => {
          context.commit("fetchAllFailure");
          reject(resp);
        });
    });
  },

  fetchAllContacts(
    context: CampaignContext,
    campaignId: string,
  ): Promise<Response> {
    return new Promise((resolve, reject) => {
      context.commit("fetchAllContactsRequest");

      CampaignApi.contacts(campaignId)
        .then((resp: Response) => {
          const data = resp.data.contacts.map((d: any) =>
            CampaignContact.fromJSON(d),
          );
          context.commit("fetchAllContactsSuccess", {
            campaignId: campaignId,
            contacts: data,
          });
          resolve(resp);
        })
        .catch((resp: Response) => {
          context.commit("fetchAllContactsFailure");
          reject(resp);
        });
    });
  },

  addContacts(
    context: CampaignContext,
    { campaignId, contactIds },
  ): Promise<Response> {
    return new Promise((resolve, reject) => {
      context.commit("addContactsRequest");

      CampaignApi.addContacts(campaignId, contactIds)
        .then((resp: Response) => {
          const data = resp.data.contacts.map((d: any) =>
            CampaignContact.fromJSON(d),
          );
          context.commit("addContactsSuccess", {
            campaignId: campaignId,
            contacts: data,
          });
          resolve(resp);
        })
        .catch((resp: Response) => {
          context.commit("addContactsFailure");
          reject(resp);
        });
    });
  },

  attachTemplate(
    context: CampaignContext,
    { campaignId, template },
  ): Promise<Response> {
    return new Promise((resolve, reject) => {
      context.commit("attachTemplateRequest");

      CampaignApi.attachTemplate(campaignId, template.id)
        .then((resp: Response) => {
          context.commit("attachTemplateSuccess", {
            campaignId: campaignId,
            template: template,
          });
          resolve(resp);
        })
        .catch((resp: Response) => {
          context.commit("attachTemplateFailure");
          reject(resp);
        });
    });
  },

  clearTemplate(
    context: CampaignContext,
    campaignId: string,
  ): Promise<Response> {
    return new Promise((resolve, reject) => {
      context.commit("attachTemplateRequest");

      CampaignApi.clearTemplate(campaignId)
        .then((resp: Response) => {
          context.commit("clearTemplateSuccess", {
            campaignId: campaignId,
          });
          resolve(resp);
        })
        .catch((resp: Response) => {
          context.commit("clearTemplateFailure");
          reject(resp);
        });
    });
  },
};

const mutations: MutationTree<CampaignState> = {
  createRequest(_state: CampaignState) {
    // Nothing yet.
  },

  createSuccess(state: CampaignState, { campaign }) {
    state.campaignIds.push(campaign.id);
    state.campaigns[campaign.id] = campaign;
  },

  createFailure(_state: CampaignState) {
    // Nothing yet.
  },

  fetchRequest(_state: CampaignState) {
    // Nothing yet.
  },

  fetchSuccess(state: CampaignState, { campaign }) {
    Vue.set(state.campaigns, campaign.id, campaign);
  },

  fetchFailure(_state: CampaignState) {
    // Nothing yet.
  },

  fetchAllRequest(_state: CampaignState) {
    // Nothing yet.
  },

  fetchAllSuccess(state: CampaignState, { campaigns }) {
    state.campaignIds = campaigns.map((c: Campaign) => c.id);
    const updatedCampaigns = campaigns.reduce(
      (acc: Record<string, Campaign>, c: Campaign) => {
        if (!c.id) {
          return acc;
        }
        acc[c.id] = c;
        return acc;
      },
      {},
    );

    Vue.set(state, "campaigns", updatedCampaigns);
  },

  fetchAllFailure(_state: CampaignState) {
    // Nothing yet.
  },

  fetchAllContactsRequest(_state: CampaignState) {
    // Nothing yet.
  },

  fetchAllContactsSuccess(state: CampaignState, { campaignId, contacts }) {
    Vue.set(state.contacts, campaignId, contacts);
  },

  fetchAllContactsFailure(_state: CampaignState) {
    // Nothing yet.
  },

  addContactsRequest(_state: CampaignState) {
    // Nothing yet.
  },

  addContactsSuccess(state: CampaignState, { campaignId, contacts }) {
    let updatedContacts: CampaignContact[] = state.contacts[campaignId] || [];
    updatedContacts = updatedContacts.concat(contacts);
    const uniqContacts = updatedContacts.filter(function (item, index) {
      return updatedContacts.indexOf(item) >= index;
    });

    Vue.set(state.contacts, campaignId, uniqContacts);
  },

  addContactsFailure(_state: CampaignState) {
    // Nothing yet.
  },

  attachTemplateRequest(_state: CampaignState) {
    // Nothing yet.
  },

  attachTemplateSuccess(state: CampaignState, { campaignId, template }) {
    const campaign = state.campaigns[campaignId] || new Campaign();
    campaign.template = template;
    Vue.set(state.campaigns, campaignId, campaign);
  },

  attachTemplateFailure(_state: CampaignState) {
    // Nothing yet.
  },

  clearTemplateRequest(_state: CampaignState) {
    // Nothing yet.
  },

  clearTemplateSuccess(state: CampaignState, { campaignId }) {
    const campaign = state.campaigns[campaignId] || new Campaign();
    campaign.template = null;
    Vue.set(state.campaigns, campaignId, campaign);
  },

  clearTemplateFailure(_state: CampaignState) {
    // Nothing yet.
  },
};

export const campaign: Module<CampaignState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
