import * as Vuex from "vuex";
import Vue from "vue";

import { auth } from "./auth";
import { campaign } from "./campaign";
import { contact } from "./contact";
import { template } from "./template";
import { user } from "./user";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    campaign,
    contact,
    template,
    user,
  },
});
