
import Api from "../api";
import { Component, Vue } from "vue-property-decorator";
import Response from "../models/response";
import SubAction from "../components/SubAction.vue";

import { extend, ValidationObserver, ValidationProvider } from "vee-validate";

import { messages } from "vee-validate/dist/locale/en.json";

import { required, email } from "vee-validate/dist/rules";

extend("required", { ...required, message: messages.required });
extend("email", { ...email, message: messages.email });

@Component({
  components: {
    SubAction,
    ValidationProvider,
    ValidationObserver,
  },
})
export default class ForgotPassword extends Vue {
  email: string | null = null;
  error: Record<string, unknown> | null = null;
  hasRequested = false;
  isLoading = false;

  get formWidth(): string {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return "300px";
      case "sm":
        return "300px";
      case "md":
        return "450px";
      case "lg":
        return "450px";
      case "xl":
        return "450px";
      default:
        return "450px";
    }
  }

  onSubmit(): void {
    Api.requestPasswordReset(this.email)
      .then((_resp: Response) => {
        this.hasRequested = true;
      })
      .catch((_resp: Response) => {
        // Nothing yet.
      });
  }
}
