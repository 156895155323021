
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "../../models/template";

@Component
export default class AddTemplate extends Vue {
  @Prop({ required: true }) readonly templates!: Template[];
  @Prop({ default: null }) readonly selectedTemplateId!: string | null;

  close(): void {
    this.$emit("close");
  }

  didSelect(template: Template): void {
    this.$emit("selected", template);
  }

  color(templateId: string | null): string {
    return this.selectedTemplateId === templateId ? "grey lighten-3" : "white";
  }
}
