
import Vue from "vue";
import AppHeader from "./components/AppHeader.vue";

export default Vue.extend({
  name: "App",

  components: {
    AppHeader,
  },

  data: () => ({
    showRoutes: [
      "Home",
      "Contacts",
      "Campaigns",
      "CampaignDetail",
      "Templates",
      "Users",
    ],
  }),
});
