
import { mapActions, mapGetters } from "vuex";
import ErrorBox from "../components/ErrorBox.vue";
import Response from "../models/response";
import Vue from "vue";

export default Vue.extend({
  name: "Login",

  components: {
    ErrorBox,
  },

  data: () => ({
    email: null,
    error: null,
    password: null,
    snackbar: true,
  }),

  computed: {
    ...mapGetters({
      isLoading: "auth/isLoading",
      loginError: "auth/getLoginError",
      profile: "auth/getProfile",
    }),

    signupRoute(): any {
      const redirectTo = this.$route.query.from;

      if (redirectTo === undefined) {
        return { path: "/signup" };
      } else {
        return { path: "/signup", query: { from: redirectTo } };
      }
    },

    formWidth(): string {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "300px";
        case "sm":
          return "300px";
        case "md":
          return "450px";
        case "lg":
          return "450px";
        case "xl":
          return "450px";
        default:
          return "450px";
      }
    },
  },

  methods: {
    ...mapActions("auth", ["fetchProfile", "login"]),

    doSuccessfulRedirect() {
      const redirectTo: any = this.$route.query.from;

      if (redirectTo === undefined) {
        this.$router.push("/contacts");
      } else {
        this.$router.push(redirectTo);
      }
    },

    requestLogin(): void {
      this.login({ email: this.email, password: this.password })
        .then((_resp: Response) => {
          this.fetchProfile()
            .then((_resp: Response) => {
              this.doSuccessfulRedirect();
            })
            .catch((_resp: Response) => {
              // Nothing
            });
        })
        .catch((_resp: Response) => {
          // Nothing
        });
    },
  },
});
