import CampaignJson from "./campaignJson";
import Template from "./template";

export default class Campaign {
  createdAt: string | null;
  name: string | null;
  id: string | null;
  sendAt: string | null;
  subject: string | null;
  template: Template | null;

  constructor() {
    this.createdAt = null;
    this.name = null;
    this.id = null;
    this.sendAt = null;
    this.subject = null;
    this.template = null;
  }

  static fromJSON(json: CampaignJson): Campaign {
    const campaign = new Campaign();

    campaign.createdAt = json.created_at;
    campaign.name = json.name;
    campaign.id = json.id;
    campaign.sendAt = json.send_at;
    campaign.subject = json.subject;

    if (json.template) {
      campaign.template = Template.fromJSON(json.template);
    }

    return campaign;
  }

  toJSON(): any {
    return {
      name: this.name,
      subject: this.subject,
      send_at: this.sendAt,
    };
  }
}
