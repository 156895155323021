import ContactApi from "@/api/contact";
import {
  ActionContext,
  ActionTree,
  GetterTree,
  Module,
  MutationTree,
} from "vuex";
import { State as RootState } from "../state";
import { ContactState } from "./contactState";
import Contact from "../../models/contact";
import Response from "../../models/response";

type ContactContext = ActionContext<ContactState, RootState>;

const state: ContactState = {
  contacts: [],
};

const getters: GetterTree<ContactState, RootState> = {
  getContacts(state: ContactState): Contact[] {
    return state.contacts;
  },
};

const actions: ActionTree<ContactState, RootState> = {
  create(context: ContactContext, { contact }): Promise<Response> {
    return new Promise((resolve, reject) => {
      context.commit("createRequest");

      ContactApi.create(contact)
        .then((resp: Response) => {
          const data: Contact = Contact.fromJSON(resp.data);
          context.commit("createSuccess", { contact: data });
          resolve(resp);
        })
        .catch((resp: Response) => {
          context.commit("createFailure");
          reject(resp);
        });
    });
  },

  fetchAll(context: ContactContext): Promise<Response> {
    return new Promise((resolve, reject) => {
      context.commit("fetchAllRequest");

      ContactApi.all({})
        .then((resp: Response) => {
          const data = resp.data.contacts.map((d: any) => Contact.fromJSON(d));
          context.commit("fetchAllSuccess", { contacts: data });
          resolve(resp);
        })
        .catch((resp: Response) => {
          context.commit("fetchAllFailure");
          reject(resp);
        });
    });
  },

  delete(context: ContactContext, contactId: string): Promise<Response> {
    return new Promise((resolve, reject) => {
      context.commit("deleteRequest");

      ContactApi.delete(contactId)
        .then((resp: Response) => {
          context.commit("deleteSuccess", { contactId: contactId });
          resolve(resp);
        })
        .catch((resp: Response) => {
          context.commit("deleteFailure");
          reject(resp);
        });
    });
  },
};

const mutations: MutationTree<ContactState> = {
  createRequest(_state: ContactState) {
    // Nothing yet.
  },

  createSuccess(state: ContactState, { contact }) {
    state.contacts.push(contact);
  },

  createFailure(_state: ContactState) {
    // Nothing yet.
  },

  fetchAllRequest(_state: ContactState) {
    // Nothing yet.
  },

  fetchAllSuccess(state: ContactState, { contacts }) {
    state.contacts = contacts;
  },

  fetchAllFailure(_state: ContactState) {
    // Nothing yet.
  },

  deleteRequest(_state: ContactState) {
    // Nothing yet.
  },

  deleteSuccess(state: ContactState, { contactId }) {
    state.contacts = state.contacts.filter((x) => x.id !== contactId);
  },

  deleteFailure(_state: ContactState) {
    // Nothing yet.
  },
};

export const contact: Module<ContactState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
