
import { Component, Vue } from "vue-property-decorator";
import TemplatesTable from "../components/templates/TemplatesTable.vue";
import Template from "../models/template";
import UploadFile from "../components/UploadFile.vue";
import { namespace } from "vuex-class";

const template = namespace("template");

@Component({
  components: {
    TemplatesTable,
    UploadFile,
  },
})
export default class Templates extends Vue {
  isCreateDialogOpen = false;
  isPreviewDialogOpen = false;
  htmlPreview: string | null = null;
  selectedTemplateId: string | null = null;
  previewTemplateId: string | null = null;
  tempTemplateName: string | null = null;

  @template.Action("create")
  createTemplate!: (params: Record<string, unknown>) => Promise<any>;

  @template.Action("fetchAll")
  fetchAllTemplates!: () => Promise<any>;

  @template.Action("delete")
  deleteTemplate!: (templateId: string) => Promise<any>;

  @template.Getter("getTemplates")
  templates!: Template[];

  get hasSelectedTemplates(): boolean {
    return this.selectedTemplateId !== null;
  }

  get previewUrl(): string {
    return `${process.env.VUE_APP_API_URL}/api/v1/templates/${this.previewTemplateId}/preview`;
  }

  mounted(): void {
    this.fetchAllTemplates();
  }

  requestCreateTemplate(name: string | null, file: File): void {
    this.createTemplate({ name: name, file: file }).then((_resp: any) => {
      this.isCreateDialogOpen = false;
      (this.$refs.uploadFile as UploadFile).reset();
      this.fetchAllTemplates();
    });
  }

  updateSelectedTemplateIds(templateIds: string[]): void {
    if (templateIds.length === 0) {
      this.selectedTemplateId = null;
    } else {
      this.selectedTemplateId = templateIds[0];
    }
  }

  deleteSelectedTemplates(): void {
    if (this.selectedTemplateId === null) {
      return;
    }
    this.deleteTemplate(this.selectedTemplateId);
    this.selectedTemplateId = null;
  }

  requestPreview(templateId: string): void {
    this.previewTemplateId = templateId;
    this.isPreviewDialogOpen = true;
  }
}
