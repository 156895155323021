import axios from "axios";
import Response from "../models/response";
import Util from "./util";

export default {
  all(params: any): Promise<Response> {
    const url = process.env.VUE_APP_API_URL + "/api/v1/templates";

    return axios
      .get(url, { params: params, headers: Util.authHeaders() })
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },

  create(name: string | null, file: File): Promise<Response> {
    const url = process.env.VUE_APP_API_URL + "/api/v1/templates";

    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", name || "");

    let headers: Record<string, string> = {};
    headers["Content-Type"] = "multipart/form-data";
    headers = Object.assign(headers, Util.authHeaders());

    return axios
      .post(url, formData, { headers: headers })
      .then((resp: any) => resp.data)
      .catch((error: any) => Util.handleResponse(error));
  },

  delete(templateId: string): Promise<Response> {
    const url = `${process.env.VUE_APP_API_URL}/api/v1/templates/${templateId}`;

    return axios
      .delete(url, { headers: Util.authHeaders() })
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },

  export(): Promise<Response> {
    const url = process.env.VUE_APP_API_URL + "/api/v1/templates/export";

    return axios
      .post(url, {}, { headers: Util.authHeaders() })
      .then((resp: any) => resp.data)
      .catch((error: any) => Util.handleResponse(error));
  },

  importCSV(file: File): Promise<Response> {
    const url = process.env.VUE_APP_API_URL + "/api/v1/templates/import";

    const formData = new FormData();
    formData.append("file", file);

    let headers: Record<string, string> = {};
    headers["Content-Type"] = "multipart/form-data";
    headers = Object.assign(headers, Util.authHeaders());

    return axios
      .post(url, formData, { headers: headers })
      .then((resp: any) => resp.data)
      .catch((error: any) => Util.handleResponse(error));
  },

  preview(templateId: string) {
    const url = `${process.env.VUE_APP_API_URL}/api/v1/templates/${templateId}/preview`;

    return axios
      .get(url, { headers: Util.authHeaders() })
      .then((resp: any) => resp)
      .catch((error: any) => Util.handleResponse(error));
  },
};
