
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Campaign from "@/models/campaign";
import ErrorBox from "../ErrorBox.vue";

import { extend, ValidationObserver, ValidationProvider } from "vee-validate";

import { messages } from "vee-validate/dist/locale/en.json";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: messages.required });

@Component({
  components: {
    ErrorBox,
    ValidationProvider,
    ValidationObserver,
  },
})
export default class CreateCampaign extends Vue {
  @Prop({ required: true }) readonly value!: boolean;

  errorMessage: string | null = null;

  isLoading = false;
  isOpen = false;

  tempCampaign: Campaign = new Campaign();
  valid = false;

  @Watch("value")
  onValueChanged(val: boolean, _oldVal: boolean): void {
    if (this.isOpen === val) {
      return;
    }
    this.isOpen = val;
  }

  @Watch("isOpen")
  onIsOpenChanged(_val: boolean, _oldVal: boolean): void {
    if (!this.isOpen) {
      this.close();
    }
  }

  close(): void {
    this.$emit("close");
  }

  onSubmit(): void {
    if (this.isLoading) {
      return;
    }

    this.isLoading = true;
    this.$emit("save", this.tempCampaign);
  }

  reset(): void {
    this.tempCampaign = new Campaign();
    this.errorMessage = null;
  }
}
