
import Api from "../api";
import { Component, Vue } from "vue-property-decorator";
import Response from "../models/response";

import { extend, ValidationObserver, ValidationProvider } from "vee-validate";

import { messages } from "vee-validate/dist/locale/en.json";

import { required, max, min, confirmed } from "vee-validate/dist/rules";

extend("has-number", {
  message(field, _args) {
    return "The " + field + " does not contain a number.";
  },
  validate(value) {
    return /\d/.test(value);
  },
});

extend("has-capital", {
  message(field, _args) {
    return "The " + field + " does not contain a capital letter.";
  },
  validate(value) {
    return /[A-Z]/.test(value);
  },
});

extend("has-special", {
  message(field, _args) {
    return "The " + field + " does not contain a special character.";
  },
  validate(value) {
    // eslint-disable-next-line
    return /[\s~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?()\._]/g.test(value);
  },
});

extend("required", { ...required, message: messages.required });
extend("max", { ...max, message: messages.max });
extend("min", { ...min, message: messages.min });
extend("confirmed", { ...confirmed, message: messages.confirmed });

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class ResetPassword extends Vue {
  errorMessage: string | null = null;
  isLoading = false;
  password: string | null = null;
  passwordConfirm: string | null = null;
  success = false;

  onSubmit(): void {
    const token: any = this.$route.query.t;

    Api.resetPassword(token, this.password)
      .then((_resp: Response) => {
        this.success = true;
        this.errorMessage = null;
      })
      .catch((resp: Response) => {
        this.errorMessage = resp.error.message;
      });
  }
}
