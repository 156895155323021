import ContactJson from "./contactJson";

export default class Contact {
  company: string | null;
  createdAt: string | null;
  email: string | null;
  firstName: string | null;
  id: string | null;
  jobTitle: string | null;
  lastName: string | null;
  phoneNo: string | null;
  subscribed: boolean;

  constructor() {
    this.company = null;
    this.createdAt = null;
    this.email = null;
    this.firstName = null;
    this.id = null;
    this.jobTitle = null;
    this.lastName = null;
    this.phoneNo = null;
    this.subscribed = true;
  }

  static fromJSON(json: ContactJson): Contact {
    const contact = new Contact();

    contact.company = json.company;
    contact.createdAt = json.created_at;
    contact.email = json.email;
    contact.firstName = json.first_name;
    contact.id = json.id;
    contact.jobTitle = json.job_title;
    contact.lastName = json.last_name;
    contact.phoneNo = json.phone_no;
    contact.subscribed = json.subscribed;

    return contact;
  }

  toJSON(): any {
    return {
      company: this.company,
      first_name: this.firstName,
      job_title: this.jobTitle,
      email: this.email,
      last_name: this.lastName,
      phone_no: this.phoneNo,
      subscribed: this.subscribed,
    };
  }

  fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}
