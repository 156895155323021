
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class UploadFile extends Vue {
  @Prop({ default: "Upload a file" }) readonly title!: string;

  files: any[] = [];

  get hasFiles(): boolean {
    return this.files.length > 0;
  }

  close(): void {
    this.$emit("close");
  }

  addFile(e: DragEvent): void {
    this.files = [];
    if (!e.dataTransfer) return;

    const droppedFiles = e.dataTransfer.files;
    if (!droppedFiles) return;

    [...droppedFiles].forEach((f) => {
      this.files.push(f);
    });
  }

  removeFile(file: File): void {
    this.files = this.files.filter((f) => {
      return f != file;
    });
  }

  reset(): void {
    this.files = [];
  }

  upload(): void {
    this.$emit("upload", this.files[0]);
  }
}
