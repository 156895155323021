export default class Pagination {
  total: number | null;
  offset: number | null;
  limit: number | null;

  constructor() {
    this.total = null;
    this.offset = null;
    this.limit = null;
  }

  static fromJSON(json: Record<string, number>): Pagination {
    const p = new Pagination();
    p.total = json.total;
    p.offset = json.offset;
    p.limit = json.limit;

    return p;
  }
}
