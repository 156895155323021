export default class Error {
  code: string | null;
  message: string | null;

  constructor() {
    this.code = null;
    this.message = null;
  }

  static fromJSON(json: Record<string, string>): Error {
    const error = new Error();
    error.code = json.code;
    error.message = json.message;

    return error;
  }
}
