
import { Component, Vue } from "vue-property-decorator";
import CreateCampaign from "../components/campaigns/CreateCampaign.vue";
import CampaignsTable from "../components/campaigns/CampaignsTable.vue";
import Campaign from "../models/campaign";
import Response from "../models/response";
import { namespace } from "vuex-class";

const campaign = namespace("campaign");

@Component({
  components: {
    CreateCampaign,
    CampaignsTable,
  },
})
export default class Campaigns extends Vue {
  createDialog = false;

  @campaign.Action("create")
  createCampaign!: (params: Record<string, unknown>) => Promise<any>;

  @campaign.Action("fetchAll")
  fetchAllCampaigns!: () => Promise<any>;

  mounted(): void {
    this.fetchAllCampaigns();
  }

  close(): void {
    this.createDialog = false;
  }

  save(campaign: Campaign): void {
    this.createCampaign({ campaign: campaign })
      .then((_resp: Response) => {
        this.close();

        const dialog: any = this.$refs.createCampaignDialog;
        dialog.reset();

        const table: any = this.$refs.campaignsTable;
        table.reload();
      })
      .catch((resp: Response) => {
        const dialog: any = this.$refs.createCampaignDialog;
        dialog.errorMessage = resp.error.message;
      })
      .finally(() => {
        const dialog: any = this.$refs.createCampaignDialog;
        dialog.isLoading = false;
      });
  }

  showDialog(): void {
    this.createDialog = true;
  }
}
