import axios from "axios";
import Response from "../models/response";
import Util from "./util";

export default {
  verify(token: string | null): Promise<Response> {
    const url = process.env.VUE_APP_API_URL + "/v1/auth/verify";

    return axios
      .post(url, { token: token })
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },

  authorize(token: string | null): Promise<Response> {
    const url = process.env.VUE_APP_API_URL + "/v1/auth/authorize";

    return axios
      .post(url, { token: token })
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },
};
