import axios from "axios";
import store from "../store";
import router from "../router";
import Response from "../models/response";
import Util from "./util";

axios.interceptors.response.use(
  (response: any) => {
    return Promise.resolve(response);
  },
  (error: any) => {
    if (error.response.status === 401) {
      store.dispatch("auth/logout");
      router.push({ name: "Login" });
    }

    return Promise.reject(error);
  },
);

export default {
  fetchProfile(): Promise<Response> {
    const url = process.env.VUE_APP_API_URL + "/api/v1/profile";

    return axios
      .get(url, { headers: Util.authHeaders() })
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },

  login(email: string, pass: string): Promise<Response> {
    const url = process.env.VUE_APP_API_URL + "/api/v1/auth/login";
    const params = { email: email, password: pass };

    return axios
      .post(url, params)
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },

  register(
    firstName: string,
    lastName: string,
    email: string,
    phoneNo: string,
    pass: string,
  ): Promise<Response> {
    const url = process.env.VUE_APP_API_URL + "/v1/auth/register";

    const params = {
      email: email,
      first_name: firstName,
      last_name: lastName,
      password: pass,
      phone_no: phoneNo,
    };

    return axios
      .post(url, params)
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },

  requestPasswordReset(email: string | null): Promise<Response> {
    const url = process.env.VUE_APP_API_URL + "/v1/auth/forgot-password";
    const params = { email: email };

    return axios
      .post(url, params)
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },

  resetPassword(
    token: string | null,
    password: string | null,
  ): Promise<Response> {
    const url = process.env.VUE_APP_API_URL + "/v1/auth/change-password";
    const params = { token: token, password: password };

    return axios
      .post(url, params)
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },
};
