import axios from "axios";
import Campaign from "../models/campaign";
import Response from "../models/response";
import Util from "./util";

export default {
  addContacts(campaignId: string, contactIds: string[]): Promise<Response> {
    const url = Util.apiUrl(`/api/v1/campaigns/${campaignId}/contacts`);
    const params = { contact_ids: contactIds };

    return axios
      .post(url, params, { headers: Util.authHeaders() })
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },

  all(params: any): Promise<Response> {
    const url = Util.apiUrl("/api/v1/campaigns");

    return axios
      .get(url, { params: params, headers: Util.authHeaders() })
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },

  attachTemplate(campaignId: string, templateId: string): Promise<Response> {
    const url = Util.apiUrl(`/api/v1/campaigns/${campaignId}/template`);
    const params = { template_id: templateId };

    return axios
      .post(url, params, { headers: Util.authHeaders() })
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },

  clearTemplate(campaignId: string): Promise<Response> {
    const url = Util.apiUrl(`/api/v1/campaigns/${campaignId}/template`);

    return axios
      .delete(url, { headers: Util.authHeaders() })
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },

  contacts(campaignId: string): Promise<Response> {
    const url = Util.apiUrl(`/api/v1/campaigns/${campaignId}/contacts`);

    return axios
      .get(url, { headers: Util.authHeaders() })
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },

  create(campaign: Campaign): Promise<Response> {
    const url = Util.apiUrl("/api/v1/campaigns");
    const params = campaign.toJSON();

    return axios
      .post(url, params, { headers: Util.authHeaders() })
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },

  export(): Promise<Response> {
    const url = Util.apiUrl("/api/v1/campaigns/export");

    return axios
      .post(url, {}, { headers: Util.authHeaders() })
      .then((resp: any) => resp.data)
      .catch((error: any) => Util.handleResponse(error));
  },

  get(campaignId: string): Promise<Response> {
    const url = Util.apiUrl(`/api/v1/campaigns/${campaignId}`);

    return axios
      .get(url, { headers: Util.authHeaders() })
      .then((resp: any) => Util.handleResponse(resp))
      .catch((error: any) => Util.handleResponse(error));
  },
};
