
import { Component, Prop, Vue } from "vue-property-decorator";
import Contact from "../../models/contact";
import ContactsTable from "../contacts/ContactsTable.vue";
import { namespace } from "vuex-class";

const campaign = namespace("campaign");
const contact = namespace("contact");

@Component({
  components: {
    ContactsTable,
  },
})
export default class AddContacts extends Vue {
  @Prop({ required: true }) readonly campaignId!: string;
  @Prop({ default: [] }) readonly existingContactIds!: string[];
  selectedContactIds: string[] = [];

  @contact.Action("fetchAll")
  fetchAllContacts!: () => Promise<any>;

  @contact.Getter("getContacts")
  allContacts!: Contact[];

  @campaign.Action("addContacts")
  addContacts!: (params: Record<string, unknown>) => Promise<any>;

  get availableContacts(): Contact[] {
    return this.allContacts.filter(
      (x) => x.id && !this.existingContactIds.includes(x.id),
    );
  }

  mounted(): void {
    this.fetchAllContacts();
  }

  addSelected(): void {
    this.addContacts({
      campaignId: this.campaignId,
      contactIds: this.selectedContactIds,
    }).then((_resp) => {
      this.selectedContactIds = [];
      this.$emit("close");
    });
  }

  addAll(): void {
    // Yo
  }

  updateSelectedContactIds(contactIds: string[]): void {
    this.selectedContactIds = contactIds;
  }
}
