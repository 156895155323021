
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Template from "../../models/template";
const { DateTime, Interval } = require("luxon");

@Component
export default class TemplatesTable extends Vue {
  @Prop({ required: true }) readonly templates!: Template[];
  @Prop({ default: false }) readonly singleSelect!: boolean;

  headers: Record<string, any>[] = [
    {
      text: "Name",
      value: "name",
    },
    {
      text: "Created",
      value: "createdAt",
    },
    {
      text: "",
      value: "actions",
      sortable: false,
    },
  ];

  isLoading = false;

  selected: string[] = [];

  options: any = {
    sortDesc: [false],
    page: 1,
    itemsPerPage: 20,
    sortBy: ["lastName"],
  };

  itemsPerPageOptions = [20, 50];
  totalItems = 0;

  @Watch("selected")
  updateSelected(newVal: Template[], _oldVal: Template[]): void {
    const templateIds = newVal.map((template) => template.id);
    this.$emit("update-selected", templateIds);
  }

  get footerProps(): any {
    return { "items-per-page-options": this.itemsPerPageOptions };
  }

  datetimeString(datetime: string): string {
    return DateTime.fromISO(datetime).toLocaleString(DateTime.DATETIME_MED);
  }

  daysAgoString(datetime: string): string {
    const dt = DateTime.fromISO(datetime);
    const days = Interval.fromDateTimes(dt, DateTime.local()).toDuration(
      "days",
    ).days;

    if (days < 1) {
      return "< 1 day";
    } else if (days >= 1 && days < 2) {
      return "1 day";
    } else {
      return `${Math.round(days)} days`;
    }
  }

  offset(): number {
    const { page, itemsPerPage } = this.options;
    return (page - 1) * itemsPerPage;
  }

  sortParam(key: string): string {
    const keys: any = {
      createdAt: "inserted_at",
      id: "id",
      name: "name",
      companyName: "company_name",
      firstName: "first_name",
      lastName: "last_name",
      jobTitle: "job_title",
      stage: "stage",
      frMrr: "fr_mrr",
      frLicenseCount: "fr_license_count",
      email: "email",
      numberOfAgents: "num_agents",
    };

    return keys[key];
  }

  daysAgoColor(datetime: string): string {
    const dt = DateTime.fromISO(datetime);
    const days = Interval.fromDateTimes(dt, DateTime.local()).toDuration(
      "days",
    ).days;

    if (days > 60) {
      return "red";
    } else if (days > 30 && days <= 60) {
      return "orange";
    } else {
      return "primary";
    }
  }

  statusColor(status: string): string {
    switch (status) {
      case "pending":
        return "grey";
      case "approved":
        return "primary";
      case "rejected":
        return "red";
      case "duplicate":
        return "red";
      default:
        return "grey";
    }
  }
}
