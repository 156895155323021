
import { Component, Vue, Watch } from "vue-property-decorator";
import Campaign from "../../models/campaign";
import CampaignApi from "../../api/campaign";
import Response from "../../models/response";
const { DateTime, Interval } = require("luxon");

@Component
export default class CampaignsTable extends Vue {
  campaigns: Campaign[] = [];

  headers: Record<string, any>[] = [
    {
      text: "Name",
      align: "left",
      value: "name",
    },
    {
      text: "Send At",
      align: "left",
      value: "sendAt",
    },
  ];

  isLoading = false;

  options: any = {
    sortDesc: [true],
    page: 1,
    itemsPerPage: 20,
    sortBy: ["sendAt"],
  };

  itemsPerPageOptions = [20, 50];
  totalItems = 0;

  get footerProps(): any {
    return { "items-per-page-options": this.itemsPerPageOptions };
  }

  @Watch("options")
  onOptionsChanged(_val: any, _oldVal: any): void {
    this.requestFetchCampaigns();
  }

  datetimeString(datetime: string): string {
    return DateTime.fromISO(datetime).toLocaleString(DateTime.DATETIME_MED);
  }

  daysAgoString(datetime: string): string {
    const dt = DateTime.fromISO(datetime);
    const days = Interval.fromDateTimes(dt, DateTime.local()).toDuration(
      "days",
    ).days;

    if (days < 1) {
      return "< 1 day";
    } else if (days >= 1 && days < 2) {
      return "1 day";
    } else {
      return `${Math.round(days)} days`;
    }
  }

  offset(): number {
    const { page, itemsPerPage } = this.options;
    return (page - 1) * itemsPerPage;
  }

  reload(): void {
    this.requestFetchCampaigns();
  }

  requestFetchCampaigns(): void {
    const { itemsPerPage, sortDesc, sortBy } = this.options;
    const offset = this.offset();

    const params = {
      offset: offset,
      limit: itemsPerPage,
      sort_by: this.sortParam(sortBy[0]),
      sort_dir: sortDesc[0] ? "desc" : "asc",
    };

    this.isLoading = true;

    // Update resp type once converted to class-syntax
    CampaignApi.all(params)
      .then((resp: any) => {
        this.campaigns = resp.data.campaigns.map((x: any) =>
          Campaign.fromJSON(x),
        );
        this.totalItems = resp.pagination.total;
      })
      .catch((_resp: Response) => {
        // Nothing yet.
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  sortParam(key: string): string {
    const keys: any = {
      createdAt: "inserted_at",
      id: "id",
      name: "name",
      companyName: "company_name",
      firstName: "first_name",
      lastName: "last_name",
      jobTitle: "job_title",
      stage: "stage",
      frMrr: "fr_mrr",
      frLicenseCount: "fr_license_count",
      email: "email",
      numberOfAgents: "num_agents",
    };

    return keys[key];
  }

  daysAgoColor(datetime: string): string {
    const dt = DateTime.fromISO(datetime);
    const days = Interval.fromDateTimes(dt, DateTime.local()).toDuration(
      "days",
    ).days;

    if (days > 60) {
      return "red";
    } else if (days > 30 && days <= 60) {
      return "orange";
    } else {
      return "primary";
    }
  }

  statusColor(status: string): string {
    switch (status) {
      case "pending":
        return "grey";
      case "approved":
        return "primary";
      case "rejected":
        return "red";
      case "duplicate":
        return "red";
      default:
        return "grey";
    }
  }
}
