import Response from "../models/response";
import Vue from "vue";

export default class Util {
  static authHeaders(): Record<string, any> {
    return { Authorization: `Bearer ${Vue.cookies.get("token")}` };
  }

  static apiUrl(path: string): string {
    return `${process.env.VUE_APP_API_URL}${path}`;
  }

  static handleResponse(resp: any): Promise<any> {
    if (!resp.response && !resp.data) {
      throw resp;
    }

    if (resp.headers["Content Type"] == "text/html") {
      return Promise.resolve(resp);
    }

    const response = Response.fromAxiosResponse(resp);
    return response.success
      ? Promise.resolve(response)
      : Promise.reject(response);
  }
}
